<template v-if="formActive">
    <FormItem id="name" :label="$t('overview.name')" v-model="commoditygroup.name" :required="true" />
    <FormItem id="minimum_height" :label="$t('masterdata.min_height')" v-model="commoditygroup.min_height" type="number" sizeItem="2" />
    <FormItem id="minimum_length" :label="$t('masterdata.min_length')" v-model="commoditygroup.min_length" type="number" sizeItem="2" />
    <FormItem id="minimum_width" :label="$t('masterdata.min_width')" v-model="commoditygroup.min_width" type="number" sizeItem="2" />
    <br>
    <FormItem id="maximum_length" :label="$t('masterdata.max_height')" v-model="commoditygroup.max_height" type="number" sizeItem="2" />
    <FormItem id="maximum_length" :label="$t('masterdata.max_length')" v-model="commoditygroup.max_length" type="number" sizeItem="2" />
    <FormItem id="maximum_width" :label="$t('masterdata.max_width')" v-model="commoditygroup.max_width" type="number" sizeItem="2" />
</template>

<script>
    
    export default {
        name: 'CommoditygroupForm',
        props: ['itemData'],
        data() {
            return {
                commoditygroup: {},
                submitted: false,
                loading: false,
                invalid: {},
                formActive: false
            }
        },
        watch: {
            itemData: function (val) {
                this.commoditygroup = val    
            },
        },
        methods: {
            active(value){
                this.formActive = value;
            },
            validate(){
                this.invalid = {};
                let valid = true;

                let required = document.querySelectorAll('[required]');

                for(let field of required) {
                    let fieldname = field.id;
                    
                    if(!this.commoditygroup[fieldname]) {
                        this.invalid[fieldname] = true;
                        valid = false;
                    }
                }

                return valid;
            },
            getData() {
                return this.commoditygroup;
            },
            setData(data) {
                this.commoditygroup = data
            },
            reset() {
                this.commoditygroup = {
                    'name': null,
                    'min_length': "",
                    'min_width': "",
                    'min_height': "",
                    'max_length': "",
                    'max_width': "",
                    'max_height': "",
                }
                this.loading = false
                this.invalid = {}
            }
        },
        mounted() {
            this.reset()

        }
    }
</script>